import React from 'react'
import Cookies from 'js-cookie';
import { Navigate, Outlet, useLocation } from 'react-router-dom';






const PrivateRoute = () => {
const {pathname} = useLocation()
const whiteList = ["/signup"]
    const token =  Cookies.get("token");
    const auth = (token && token !== undefined && token !== "undefined") || whiteList.includes(pathname);
    return auth ? <Outlet /> : <Navigate to="/" />;
}

export default PrivateRoute
