import { NotificationManager } from "react-notifications";
import { getVideoDetail, getVideoFromChennelId } from "../services/Api";
import { useState } from "react";
import Cookies from "js-cookie";

function GetVideoFromId() {
  const [chanleId, setChanelId] = useState(null);
  const [videoId, setVideoId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [btn, setBtn] = useState(false);

  const downloadFile = async (url) => {
    if (!url) return;
    const link = document.createElement("a");
    link.href = url;
    link.download = "Client-details";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
    await NotificationManager.success("50 Videos downloaded successfully");
    setLoading(false);
  };

  const chanelIdHandler = async (e) => {
    e.preventDefault();
    if (!chanleId || chanleId.trim().length === 0 || loading) {
      return;
    }
    setLoading(true);
    let pageToken = Cookies.get(chanleId);
    if (pageToken === undefined) {
      pageToken = "";
    }
    await getVideoFromChennelId(chanleId, pageToken).then((res) => {
      setTimeout(async () => {
        if (res.success) {
          Cookies.remove(chanleId);
          if (res?.pageToken) {
            setBtn(true);
            Cookies.set(chanleId, res?.pageToken, { expires: 1 });
          }
          downloadFile(res?.fileLink);
        } else {
          setLoading(false);
          await NotificationManager.success(res?.message);
          Cookies.remove(chanleId);
          setBtn(false);
        }
      }, 1000);
    });
  };

  const videoIdHandler = async (e) => {
    e.preventDefault();
    if (!videoId || videoId.trim().length === 0 || loading) {
      return;
    }
    setLoading(true);
    await getVideoDetail(videoId).then((res) => {
      setTimeout(async () => {
        if (res.success) {
          downloadFile(res?.fileLink);
        } else {
          setLoading(false);
          await NotificationManager.success("Video id is not found");
        }
      }, 1000);
    });
  };

  const getNext50Videos = async (e) => {
    const pageToken = Cookies.get(chanleId);
    if (pageToken.length > 1) {
      chanelIdHandler(e);
    }
  };

  return (
    <div className=" bg-secondary bg-gradient min-vh-100 ">
      <div className="container h-100 mt-5 pt-5 arya  pb-5">
        <h1 className="text-center ">Get Videos from Youtube Api</h1>
        <div className="accordion text-center" id="accordionExample">
          <div className="accordion-item bg-dark">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button text-center"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                <strong>Get all videos of channel from Channel-Id</strong>
              </button>
            </h2>
            <div
              id="collapseOne"
              className="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <form
                className="row g-3 needs-validation"
                onSubmit={chanelIdHandler}
                noValidate
              >
                <div className=" ">
                  <div className="input-group has-validation p-3 row">
                    <span
                      className="input-group-text col-sm-1"
                      id="inputGroupPrepend"
                    >
                      Channel-Id
                    </span>
                    <input
                      type="text"
                      className="form-control col-sm-4"
                      id="validationCustomUsername"
                      onChange={(e) => setChanelId(e.target.value)}
                      aria-describedby="inputGroupPrepend"
                      required
                    />
                    <div className="invalid-feedback">Channel-Id</div>
                    <div className="col-sm-4"></div>
                    {btn && chanleId ? (
                      <button
                        className="btn btn-primary col-sm-2 "
                        type="submit"
                        onClick={(e) => getNext50Videos(e)}
                      >
                        {loading ? (
                          <div className="spinner-border" role="status">
                            <span className="sr-only"></span>
                          </div>
                        ) : (
                          "Download next 50"
                        )}
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary col-sm-2 ms-2"
                        type="submit"
                      >
                        {loading ? (
                          <div className="spinner-border" role="status">
                            <span className="sr-only"></span>
                          </div>
                        ) : (
                          "Generate Excel"
                        )}
                      </button>
                    )}
                  </div>
                </div>
                <div className="col-12"></div>
              </form>
            </div>
          </div>
          <div className="accordion-item bg-dark">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button collapsed justify-content-center"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                <strong>Get single video details from Video-Id</strong>
              </button>
            </h2>
            <div
              id="collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <form
                className="row g-3 needs-validation"
                onSubmit={videoIdHandler}
                noValidate
              >
                <div className=" ">
                  <div className="input-group has-validation p-3 row">
                    <span
                      className="input-group-text col-sm-1"
                      id="inputGroupPrepend"
                    >
                      Video-Id
                    </span>
                    <input
                      type="text"
                      className="form-control col-sm-4"
                      id="validationCustomUsername"
                      onChange={(e) => setVideoId(e.target.value)}
                      aria-describedby="inputGroupPrepend"
                      required
                    />
                    <div className="invalid-feedback">Video-Id</div>
                    <div className="col-sm-4"></div>
                    <button className="btn btn-primary col-sm-2" type="submit">
                      {loading ? (
                        <div className="spinner-border" role="status">
                          <span className="sr-only"></span>
                        </div>
                      ) : (
                        " Generate Excel"
                      )}
                    </button>
                  </div>
                </div>
                <div className="col-12"></div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GetVideoFromId;
