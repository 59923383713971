import "./App.css";
import Login from "./components/Login.js";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import GetVideoFromId from "./components/GetVideoFromId";
import { NotificationContainer } from "react-notifications";
import PrivateRoute from "./components/procetectedRoutes";
import Signup from "./components/Signup.js";

function App() {
  

  return (
    <>
      <NotificationContainer />
      <Router>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path='/' element={<PrivateRoute  />}>
            <Route exact path="/get_data" element={<GetVideoFromId />} />
            <Route exact path="/signup" element={<Signup />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
