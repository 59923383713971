/* eslint-disable no-useless-escape */
import { useState } from "react";
import { NotificationManager } from "react-notifications";
import { userSignup,} from "../services/Api";
import { useNavigate } from "react-router";

function Signup() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const [otp, setOtp] = useState(null)
  const [mailError, setMailError] = useState(null);
  // const [signUp, setSignUp] = useState(false)


  const nevigate = useNavigate();

  const checkEmail = (email) => {
    const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    const match = email.match(regex);
    if (!match) {
      setMailError("Invalid Email");
    } else {
      setMailError(null);
    }
  };

  const signup = async () => {
    let item = { email, password };
    if (mailError) {
      return;
    }
    const res = await userSignup(item);
    if (res?.success) {
      NotificationManager.success(res?.messsage);
      // setSignUp(true)
      nevigate('/');
    } else {
      NotificationManager.error(res?.message);
    }
  };

  // const verify = async () =>{
  //   let values = {email, otp};
  //   console.log(values)
  //   const res = await verifyOtp(values)
  //   if (res?.success) {
  //       NotificationManager.success(res?.message);
  //       nevigate('/');
  //     } else {
  //       NotificationManager.error(res?.message);
  //     }
  // }

  // const otpHandler = (value) =>{
  //   if(typeof Number(value) === "number"){
  //       setOtp(parseInt(value));
  //   }
    
  // }

  return (
    <div className="container-fluid bg-secondary bg-gradient min-vh-100 ">
      <div className="container d-flex align-items-center justify-content-center p-2">
        <img src=" logo.png" alt="" className="rounded p-4" />
      </div>
      <h1 className="text-center text-light ">YouTube Api Data </h1>
      <div className="container bg-dark w-25 m-auto p-5 mt-5">
        <h4 className="text-center text-light">Enter Your Details</h4>
        <div className="form-floating mb-3  ">
          <input
            type="email"
            className="form-control"
            id="floatingInput"
            placeholder="name@example.com"
            onChange={(e) => {
              setEmail(e.target.value);
              checkEmail(e.target.value);
            }}
          />
          <label htmlFor="floatingInput">Email address</label>
        </div>
        <small className="text-danger">{mailError}</small>
        {/* {!signUp && 
        <> */}
        <div className="form-floating mb-3  ">
          <input
            type="password"
            className="form-control"
            id="floatingPassword"
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <label htmlFor="floatingPassword">Password</label>
        </div>
        <button type="button" className="btn btn-primary w-100" onClick={signup}>
        SignUp
      </button>
      {/* </>
        } */}

        {/* {signUp && 
        <>
        <div className="form-floating mb-3  ">
          <input
            type="number"
            className="form-control"
            value={otp}
            id="floatingPassword"
            placeholder="Enter OTP"
            onChange={(e) => otpHandler(e.target.value)}
          />
          <label htmlFor="floatingPassword">Otp</label>
        </div>
        <button type="button" className="btn btn-primary w-100" onClick={verify}>
        Verify Otp
      </button>
        </>
        } */}
        
      </div>
    </div>
  );
}

export default Signup;
