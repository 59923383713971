import axios from "axios";
import Cookies from "js-cookie";


export const authHeader = (isToken) => {
    const token = Cookies.get("token");
    if (isToken) {
      return {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
    } else {
      return {
        headers: {
          "Content-Type": "application/json",
        },
      };
    }
  };

export const userLogin = async(data) =>{
try{
    let result = await axios.post('https://api.reppedmusic.com/user/login',data);
    if(result.status) return result.data;
    else return result
}
catch(error){ 
  console.log(error)
    return error.response.data;
}
}

export const userSignup = async(data)=>{
  try {
    let result = await axios.post('https://api.reppedmusic.com/user/signup',data);
    if(result.status) return result.data;
    else return result
  } catch (error) {
    console.log(error)
    return error.response.data;
  }
}

// export const verifyOtp = async(data)=>{
//   try {
//     const values = {
//       "type": "email",
//       "action": "verify_email",
//       ...data
//   }
//     let result = await axios.post('https://api.reppedmusic.com/user/verify_otp',values);
//     if(result.status) return result.data;
//     else return result
//   } catch (error) {
//     console.log(error)
//     return error.response.data;
//   }
// }

export const getVideoFromChennelId = async (data,pageToken) =>{
    try{
        let result = await axios.post('https://api.reppedmusic.com/user/get_channel_details',{channel_id:data,nextPageToken:pageToken}, authHeader(true));
        if(result.status) return result.data;       
        else return result
    }
    catch(error){ 
        return error.response.data;
    }
}

export const downloadExcelByChennelId = async(route) =>{
    try{
        let result = await axios.get(route);
        if(result.status) return result.data;
        else return result
    }
    catch(error){ 
        return error.response.data;
    }
}

export const getVideoDetail = async (data) =>{
    try{
        let result = await axios.post('https://api.reppedmusic.com/user/get_video_details',{video_id:data}, authHeader(true));
        if(result.status) return result.data;       
        else return result
    }
    catch(error){ 
        return error.response.data;
    }
}